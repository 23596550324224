.notes-container {
    padding: 16px;
   
  }
  
  .note-item {
    border: 1px solid #ddd;
    margin-bottom: 8px;
    border-radius: 4px;
    overflow: hidden;
  }
  
  .note-header {
    display: flex;
    align-items: center;
    padding: 10px;
    background: #f7f7f7;
    cursor: pointer;
  }
  
  .note-subject {
    font-weight: bold;
    margin-right: auto;
  }
  
  .note-date {
    margin: 0 10px;
    font-size: 0.9em;
    color: #666;
  }
  
  .note-toggle-icon {
    font-size: 0.9em;
    color: #333;
    margin-left: 10px;
  }
  
  .note-content {
    padding: 10px;
    background: #fff;
  }
  .ql-editor {
    
    min-height: 200px; /* Ensures a minimum height */
  }