.edit_stages_case_stage{
    background-color: #0070c0;
    color: white;
    padding: 0.5rem 0.7rem;
    border-radius: 25px;
    border: none;
}
.tellUs_stages_case_stage{
    padding: 0.5rem 0.7rem;
    border-radius: 25px;
    border: none;   
}
.save_stages_case_stage{
    background-color: #0070c0;
    color: white;
    padding: 0.5rem 0.7rem;
    border-radius: 25px;
    border: none;
}
.addnew_stages_case_stage{

    color: #000;
    padding: 0.5rem 0.7rem;
    border-radius: 25px;
    border: none;
}
.addnew_stages_case_stage:hover{
background-color: #000;
    color: #fff;
    padding: 0.5rem 0.7rem;
    border-radius: 25px;
    border: none;
}
.cancel_stages_case_stage{
    border: none;
    background: none;
}
.cancel_stages_case_stage:hover{
    text-decoration: underline;
    border: none;
    background: none;
}