

@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Manrope:wght@200..800&display=swap');
h1{
  font-family: "Kanit", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  color: #3c3c3c;
  font-size: 38px;
}
body {        background-color: #ffffff;
  font-family: "Manrope", sans-serif;
  font-weight: 500;
  background-color: #edf4ff !important;

}
button{
  font-family: "Manrope", sans-serif;
}

/* Main grid container */
.column-3 {
  grid-template-columns: 1fr 1fr 1fr;
 
}
.column-4 {
  grid-template-columns: 1fr 1fr 1fr 1fr;
 
}
.column-5 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
 
}

.column-1,.column-2,.column-3,.column-4,.column-5,.column-2-lg-r{
display: grid;
  gap: 10px;                           /* space between columns */
 

}

.column-2 {
  grid-template-columns: 1fr 1fr ; /* 3 equal-width columns */

 
}
.column-2-lg-r {
  grid-template-columns: 60% 40% ; 

 
}



.css-fj2zrn-JoyButton-root {
  background-color: #1a2b49 !important;
}

.css-19midj6 {


  background-color: #ffffff !important;
  border-radius: 20px !important;
  margin-top: 1rem;

}
.css-7ncvzd-JoyTabs-root{
 
  background-color: #ffffff !important;
  border-radius: 20px !important;
  margin-top: 1rem;

}
.css-oq701o-JoyTabs-root{

  background-color: #ffffff !important;
  border-radius: 20px !important;
  margin-top: 40px !important;
  margin-bottom: 40px !important;
  margin: 0rem !important;
}
.css-16a2n6t-JoyContainer-root{
  
  /* background-color: #ffffff !important; */
  border-radius: 20px !important;
  margin-top: 40px !important;
  margin-bottom: 40px !important;
  
}
.css-19kzrtu{
   
  background-color: #ffffff !important;
  border-radius: 20px !important;
  margin-top: 8px !important;
  margin-bottom: 40px !important; 
}
.css-1qqhg49-JoyTable-root{

  background-color: #ffffff !important;
  border-radius: 20px !important;
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.css-1443u2l{
  background-color: #ffffff !important;
   border-radius: 20px !important;
   margin-top: 40px !important;
   margin-bottom: 40px !important; 
   padding: 1rem;
 }
.css-1pqzknd-JoyTable-root{
  
  background-color: #ffffff !important;
  border-radius: 20px !important;
  margin-top: 0px !important;
  margin-bottom: 40px !important;
}
.css-xv7cu8-JoyListItemButton-root {
  background-color: #ffb800 !important;
    border-radius: 10px !important;
}

.css-o91sbp{
 
  background-color: #ffffff !important;
  border-radius: 20px !important;
  margin-top: 8px !important;
  margin-bottom: 40px !important; 
}
.MuiListItemButton-root:not(.Mui-selected, [aria-selected="true"]):hover {
  background-color: #ffb800 !important;
 
  border-radius: 10px !important;
}


.css-1g9sp2s {
 
  margin: auto;
  background-color: #fff !important;
  border-radius: 20px !important;
}
.css-1f6us8p{
  display: flex !important;
  flex-direction: column !important;
  top: 12% !important;
}